<template>
  <div class="orderList">
    <div class="orderHeader flex j-between p-top-10 TopTongji_line">
      <div class="flex j-between p-lr-20 TopTongji" style="flex:1">
        <!-- -1：全部， 21-等待人工处理，22-部分处理，23-已全部处理，103-全部关闭订单，1-我的订单 -->
        <!-- -1：全部， 0-待处理，3 已完成 4 部分发货，103-全部关闭订单，1-我的订单 -->
        <div :class="orderNumType === -1 ? orderNumClassActive : orderNumClass" @click="changeType(-1, 2)">所有订单：<span class="headerFonts">{{ ordderNumList.num1 }}</span></div>
        <div :class="orderNumType === 21 ? orderNumClassActive : orderNumClass" @click="changeType(21, 2)">待处理订单：<span class="headerFonts headerFontscolor">{{ ordderNumList.num2 }}</span></div>
        <!-- <div :class="orderNumType === 22 ? orderNumClassActive : orderNumClass" @click="changeType(22)">部分处理订单：<span class="headerFonts headerFontscolor p-left-10">{{ ordderNumList.num3 }}</span></div>
         -->
        <div :class="orderNumType === 23 ? orderNumClassActive : orderNumClass" @click="changeType(23, 2)">已处理订单：<span class="headerFonts">{{ ordderNumList.num4 }}</span></div>
        <div :class="orderNumType === 103 ? orderNumClassActive : orderNumClass" @click="changeType(103, 2)">已关闭订单：<span class="headerFonts">{{ ordderNumList.num5 }}</span></div>
        <div :class="orderNumType === 1 ? orderNumClassActive : orderNumClass" @click="changeType(1, 2)">我的订单：<span class="headerFonts">{{ ordderNumList.num6 }}</span></div>
      </div>
      <div class="headerright">
        <el-button v-if="$minCommon.checkMeau('dkxdvaletorder', userBottons)" class="ModelBttton-white-24" type="primary" round @click="$router.push({ name: 'orderCustomers'})">代客下单</el-button>
      </div>
    </div>
    <!--    v-loading.fullscreen.lock="fullscreenLoading"-->
    <div class="p-lr-20">
      <div v-if="$minCommon.checkMeau('ddclview', userBottons)" class="searchView  flex a-center m-tb-10 j-between">
        <div class="flex" style="flex:1">
          <el-input v-model="listParas.KeyWords" size="medium" placeholder="可通过终端、订单号、交货单号、 处理人进行搜索" class="rule-input-edit SearchBox_30">
            <template slot="prepend">
              <div class="cursor" @click="adisShow = true">
                <i class="el-icon-s-operation m-right-5" />高级搜索</div></template>
            <div slot="append" @click="serchOrder()">
              <span class="cursor">搜  索</span>
            </div>
          </el-input>
          <div class="out p-left-10  flex a-center">
            <div class="m-right-10">
              <el-select v-model="listParas.PurchaseStatus" placeholder="请选择" size="mini" :clearable="true" @change="changePurchaseType">
                <el-option label="未转采" :value="1" />
                <el-option label="部分转采" :value="2" />
                <el-option label="全部转采" :value="3" />
              </el-select>
            </div>
            <el-button v-if="[-1,21,1].indexOf(orderNumType) > -1 && $minCommon.checkMeau('ddclclose', userBottons)" class="ModelBttton-white-30" round @click="comfirmColseOrder()">批量关闭订单</el-button>
            <el-button v-if="$minCommon.checkMeau('ddclexport', userBottons)" class="ModelBttton-white-30" round type="primary" @click="dealExportData"><i class="el-icon-download m-right-10" />导出</el-button>
          </div>
        </div>
      </div>
      <div v-if="ias !== 'production'">
        <SearchKeys :search-keys="searchKeys" @close="closeTag" />
      </div>
      <!-- @filter-change="tablechage"-->
      <el-table
        ref="multipleTable"
        v-loading="tableDataloading"
        :header-cell-style="{background:'rgb(247,248,252)'}"
        :data="orderList"
        tooltip-effect="dark"
        show-header
        border
        height="calc(100vh - 315px)"
        :row-class-name="tableRowClassName"
        fit
        @mouseleave.native="mouseLive"
        @cell-mouse-enter="mouseEnter"
        @cell-mouse-leave="mouseOut"
        @filter-change="tablechage"
        @selection-change="orderlisthandleSelectionChange"
      >
        <af-table-column
          fixed="left"
          type="selection"
          width="35"
        />
        <el-table-column
          prop="OrderID"
          label="订单编号"
          width="95"
        >
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" @click="$router.push({ name: 'orderDetails1', params: {orderid: scope.row.OrderID, ishandler: '-1' }} )">{{ scope.row.OrderID }}</el-link>

            <div v-if="chioceSetIndex === scope.$index" class="hoverDom flex j-end a-center">
              <div class="hoverDomRow flex a-center j-end">
                <!--   hoverInter    // #DDE8F2;  :class="isShowDomAnimate ? 'flex a-center hoverOut' : 'flex a-center hoverInter' "      -->
                <div style="background-color: #DDE8F2;" class="flex a-center j-end hoverWidth" :class="isShowDomAnimate ? 'flex a-center hoverOut' : 'flex a-center hoverInter' ">
                  <div v-if="$minCommon.checkMeau('ddclview', userBottons)" class="OperationButton">
                    <!-- <el-link :underline="false" @click="$router.push({ name: 'orderDetails1', params: {orderid: scope.row.OrderID, ishandler: '-1' }} )"><i class="el-icon-edit p-right-10" />查看订单</el-link>-->
                    <el-button type="primary" size="mini" @click="$router.push({ name: 'orderDetails1', params: {orderid: scope.row.OrderID, ishandler: '-1' }} )">查看</el-button>
                  </div>
                  <div v-if="[100,101,102,103].indexOf(scope.row.OrderStatus) < 0 && $minCommon.checkMeau('ddcldeal', userBottons)" class="OperationButton m-lr-10">
                    <!--                    <el-link :underline="false" @click="setOrder(scope.row.OrderID)"><i class="el-icon-edit p-right-10" />处理订单</el-link>-->
                    <el-button type="success" size="mini" @click="setOrder(scope.row.OrderID)">处理</el-button>
                  </div>
                  <div v-if="[23,22,100,101,102,103].indexOf(scope.row.OrderStatus) < 0 && $minCommon.checkMeau('ddclclose', userBottons)" class="OperationButton m-right-10">
                    <!--                    <el-link :underline="false" @click="ColseOrder(scope.row.OrderID)"><i class="el-icon-switch-button p-right-10" />关闭订单</el-link>-->
                    <el-button type="info" size="mini" @click="ColseOrder(scope.row.OrderID)">关闭</el-button>
                  </div>
                </div>
              </div>

            </div>
            <div v-if="chioceSetIndex === scope.$index" id="clickBg" class=" flex a-center j-center">
              <i style="background-color: #fff;padding: 5px" :class="isShowDomAnimate ? 'el-icon-d-arrow-left f14 cursor' : 'el-icon-d-arrow-right f14 cursor '" @click.stop="hideSetDom" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="CompanyName"
          label="终端"
          show-overflow-tooltip
          width="160"
        />
        <el-table-column
          prop=""
          label="买方"
          show-overflow-tooltip
          width="160"
        >
          <template slot-scope="scope">
            <!-- 如果angentid <1 那么companyid 就是买方  如果大于0 angentid就是买方 sellcompanyid 就是卖方  终端是companyid -->
            <span v-if="scope.row.AgentCompanyID < 1">{{ scope.row.CompanyName }}</span>
            <span v-if="scope.row.AgentCompanyID > 0">{{ scope.row.AgentCompanyName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="WriteTime"
          label="订单创建时间"
          show-overflow-tooltip
          width="140"
        >
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column
          prop="ExpectedDeliveryDateShow"
          label="期望到货日期"
          show-overflow-tooltip
          width="100"
        />
        <el-table-column
          prop="CreateUserName"
          label="创建人"
          show-overflow-tooltip
          width="120"
        />
        <el-table-column
          v-if="[-1,1].indexOf(orderNumType) > -1 "
          :key="Math.random()"
          label="订单状态"
          column-key="orderstatus"
          :filter-method="orderstatusfilterTag(1)"
          :filters="[ {text: '待处理订单', value: 21}, {text: '已处理订单', value: 23}, {text: '已关闭订单', value: 103}]"
          width="100"
        >
          <template slot-scope="scope">
            <span> {{ getInfo(scope.row.OrderStatus).text }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="[21,22,23,103].indexOf(orderNumType) > -1 "
          :key="Math.random()"
          label="订单状态"
        >
          <template slot-scope="scope">
            <span> {{ getInfo(scope.row.OrderStatus).text }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="是否转采"
          prop="NumToPRStr"
          show-overflow-tooltip
          width="80"
        />
        <el-table-column
          prop="Remark"
          label="订单备注"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.Remark }}

          </template>
        </el-table-column>
        <el-table-column
          prop=""
          label="添加到我的订单"
          show-overflow-tooltip
          width="110"
        >
          <template slot-scope="scope">
            <!-- <span v-if="scope.row.IsMyFavoriteOrder === 2" ><i class="el-icon-star-off Favorate m-right-10"/>
                    <el-link type="primary" @click="addFavorate(scope.row)" :underline="false">添加</el-link></span> -->
            <span v-if="scope.row.IsMyFavoriteOrder === 1 && $minCommon.checkMeau('ddclcancel', userBottons)">
              <el-link type="info" :underline="false" @click="addFavorate(scope.row)"><i class="el-icon-delete Favorate m-right-5" style="font-size:12px" />取消</el-link></span>
            <span v-if="(scope.row.IsMyFavoriteOrder === null || scope.row.IsMyFavoriteOrder === 2) && $minCommon.checkMeau('ddcladd', userBottons)">
              <el-link type="primary" :underline="false" @click="addFavorate(scope.row)"><i class="el-icon-shopping-cart-2 Favorate m-right-5" style="font-size:12px" />添加</el-link></span>
          </template>
        </el-table-column>

        <!--        <el-table-column-->
        <!--          fixed="right"-->
        <!--          prop="set"-->
        <!--          label="操作"-->
        <!--          label-position="mid"-->
        <!--          align="center"-->
        <!--          width="50"-->
        <!--        >-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-popover-->
        <!--              placement="left"-->
        <!--              trigger="hover"-->
        <!--              :width="50"-->
        <!--              popper-class="test_pop_view"-->
        <!--            >-->
        <!--              <div class="DropDownList">-->
        <!--                <div v-if="$minCommon.checkMeau('ddclview', userBottons)" class="OperationButton">-->
        <!--                  <el-link :underline="false" @click="$router.push({ name: 'orderDetails1', params: {orderid: scope.row.OrderID, ishandler: '-1' }} )"><i class="el-icon-edit p-right-10" />查看订单</el-link>-->
        <!--                </div>-->
        <!--                <div v-if="[100,101,102,103,23].indexOf(scope.row.OrderStatus) < 0 && $minCommon.checkMeau('ddcldeal', userBottons)" class="OperationButton">-->
        <!--                  <el-link :underline="false" @click="setOrder(scope.row.OrderID)"><i class="el-icon-edit p-right-10" />处理订单</el-link>-->
        <!--                </div>-->
        <!--                <div v-if="[23,22,100,101,102,103].indexOf(scope.row.OrderStatus) < 0 && $minCommon.checkMeau('ddclclose', userBottons)" class="OperationButton">-->
        <!--                  <el-link :underline="false" @click="ColseOrder(scope.row.OrderID)"><i class="el-icon-switch-button p-right-10" />关闭订单</el-link>-->
        <!--                </div>-->
        <!--                &lt;!&ndash; <div class="m-tb-10" v-if="scope.row.Status === 1">-->
        <!--                        <i class="el-icon-edit p-right-10" />-->
        <!--                        <span><el-link type="primary" :underline="false" >打印销售单</el-link></span>-->
        <!--                    </div>-->
        <!--                    <div class="m-tb-10" v-if="scope.row.Status === 2">-->
        <!--                        <i class="el-icon-edit p-right-10" />-->
        <!--                        <span><el-link type="primary" :underline="false">留言</el-link></span>-->
        <!--                    </div> &ndash;&gt;-->
        <!--              </div>-->
        <!--              <i slot="reference" class="el-icon-more" />-->
        <!--            </el-popover>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :total="tableTotalSize"
          :current-page="listParas.PageIndex"
          :page-size="listParas.PageSize"
          :background="true"
          layout="prev, pager, next, jumper"
          @current-change="orderlisthandleCurrentChange"
        />
      </div>
      <!--订单高级搜索-->
      <Adsearch :form="listParas" :ad-search-form="adSearchForm" :show="adisShow" @cancel="(e) => adisShow = e" @searchAd="AdsearchList" />
      <!-- 导出 -->
      <ExportLoading ref="ExportLoading" />
    </div>
  </div>
</template>
<script>
import orderList from '@/minxin/orderMananger/orderList.js'
export default orderList
</script>
<style scoped lang='scss'>
// @import '@/style/index.scss';
@import '@/style/search.scss';
// @import '@/style/main.scss';
@import '@/style/order.scss';
.el-table {
  /deep/tbody tr:hover>td {
    background-color:#DDE8F2;
  }
}
///* 去掉鼠标悬浮效果 */
//>>> .el-table tbody tr:hover > td {
//  background-color: transparent !important
//}
.testBtbTable {
  position: absolute;
  right: 0;
  z-index: 123123;
}

</style>
