import PageHeader from '@/components/PageHeader.vue'
import $orderReq from '@/request/orderApi.js'
import $commonJs from '@/minxin/commonJs.js'
import root from '@/minxin/root'
import Adsearch from '@/components/Adsearch.vue'
const orderList = {
  name: 'orderList',
  components: {
    PageHeader,
    Adsearch
  },
  created() {
    // 屏幕高度
    // const heihgt = document.body.clientHeight
    // // 减去的高度
    // const cosheihgt = 330
    // this.tableheight = heihgt - cosheihgt
    // this.currenttableheight = this.$minCommon.SetTableHeight(330)
    // console.log(this.tableheight)
  },
  mixins: [root],
  watch: {
    isShowDomAnimate(a) {
      // this.$nextTick(() => {
      //   const dom = document.getElementsByClassName('hoverDom')[0]
      //   const hei_ = document.getElementsByClassName('el-table__row')[0].offsetHeight
      //   if (a) {
      //     setTimeout(() => {
      //       return dom.style.display = 'none'
      //     }, 1000)
      //     return
      //   }
      //   // setTimeout(() => {
      //   //   if (!a) {
      //   //     return dom.style.display = 'block'
      //   //   }
      //   // }, 1000)
      //   dom.style.display = 'block'
      //   const btn_hei = document.getElementsByClassName('OperationButton')[0].offsetHeight
      //   dom.style.top = Math.floor((hei_ - btn_hei) / 2) + 'px'
      //   return
      // })
    },
    chioceSetIndex(a) {
      this.isShowDomAnimate = false
    },
    isOutMouse(a) {
      // console.log('鼠标在外面?', a)
      // if (a) return this.chioceSetIndex = null
    }
  },
  data() {
    return {
      chioceSetIndex: null, // 鼠标滑动索引
      isShowDomAnimate: false, // 显示淡入淡出动画
      isClick: false,
      rowIdxMouse: null, // 滑入行的唯一索引
      isOutMouse: true, // 鼠标是否在外面
      isScorllWidth: 0, // 滚动条横向滚动距离
      root_name: 'ddcl,dkxd',
      root_msg: '订单列表',
      fullscreenLoading: false,
      isShowExport: false,
      // 头部  begin ===================================================begin ===================================================
      orderNumType: 21, // 1:所有 2：待处理 3:部分处理 4：已处理 5：已关闭 6：我的订单 -1：全部， 21-等待人工处理，22-部分处理，23-已全部处理，103-全部关闭订单，1-我的订单
      orderNumClass: 'hederleft cursor',
      orderNumClassActive: 'orderheadactive hederleft cursor',
      ordderNumList: {
        num1: null,
        num2: null,
        num3: null,
        num4: null,
        num5: null,
        num6: null
      },
      // 头部  end ===================================================end ===================================================
      // 订单列表查询  begin ===================================================begin ===================================================
      listParas: {
        OrderStatus: 21,
        OrderType: 0,
        KeyWords: '',
        PageIndex: 1,
        PageSize: 20,
        ShipmentStatus: null, // 发货状态  查询 传 0 查询 部分发货/未发货 3已发货 -1 查询所有状态
        CreateUserName: null,
        BuyCompanyName: null,
        SendCompanyName: null,
        OrderId: null,
        DateArry: [],
        BeginDate: null,
        EndDate: null,
        PurchaseStatus: null
      },
      searchKeys: [],
      ias: process.env.NODE_ENV,
      adSearchForm: [
        { name: '订单编号', value: 'OrderId' },
        { name: '终端', value: 'SendCompanyName' },
        { name: '买方', value: 'BuyCompanyName' },
        { name: '创建人', value: 'CreateUserName' },
        { name: '创建订单时间区间', value: 'DateArry', date: true }
      ],
      // 订单列表数据
      orderList: [
        // {
        //   IDX: 1,
        //   OrderID: 'fdsafsd', // 订单id
        //   CompanyName: '终端',
        //   AgentCompanyName: '买方',
        //   AgentCompanyID: 0,
        //   WriteTime: '订单创建时间',
        //   qwdhsj: '期望到货时间', // 未定
        //   CreateUserName: '创建人',
        //   OrderStatus: 0, //  --订单状态统计：0-前台未审核；1-前台已审核；11-系统尚未确认；2-后台已确认；21-需人工介入；3-已全部发货；4-已部分发货；5-用户已确认；100-用户手动关闭；101-后台手动关闭；102-后台自动关闭,103-所有关闭订单
        //   fhstatus: 1, // 发货状态 // 未定
        //   OrderIDSource: 10, // 订单来源
        //   Remark: '订单备注',
        //   IsMyFavoriteOrder: 2 // IsMyFavoriteOrder 1是，2否
        // }
      ],
      tableTotalSize: 0, // 总条数
      tableDataloading: false,
      checkedOrderList: [], // 选中的订单数据集 (idx)
      AdvancedsearchVisibel: {}, // 高级搜索
      rowIdx: null,
      orderstatusList: [
        { text: '待处理订单', value: 21 },
        { text: '部分处理订单', value: 22 },
        { text: '已处理订单', value: 23 },
        { text: '已关闭订单', value: 100 }
        // { text: '已关闭订单', value: 101 },
        // { text: '已关闭订单', value: 102 },
        // { text: '已关闭订单', value: 103 }
        // { text: '前台未审核', value: 0 },
        // { text: '前台已审核', value: 1 },
        // { text: '系统尚未确认', value: 11 },
        // { text: '后台已确认', value: 2 },
        // { text: '需人工介入', value: 21 },
        // { text: '已全部发货', value: 3 },
        // { text: '已部分发货', value: 4 },
        // { text: '用户已确认', value: 5 },
        // { text: '用户手动关闭', value: 100 },
        // { text: '后台手动关闭', value: 101 },
        // { text: '后台自动关闭', value: 102 },
        // { text: '所有关闭订单', value: 103 }
      ],
      ordersendstatusList: [
        { text: '未发货', value: 0 },
        { text: '已部分发货', value: 4 },
        { text: '已发货', value: 3 }
      ],
      // 订单列表查询  end ===================================================end ===================================================
      statusType: 0, // 1 订单状态筛选 2：发货状态筛选
      adisShow: false // 高级搜索是否显示
    }
  },
  mounted() {
    // 获取按钮权限
    // this.getBottons('ddcl')
    this.getOrderTypeListNum()
    this.getOrderList()
    // this.$refs.multipleTable.addEventListener('scroll', (event) => {
    //   this.getDistance(event)
    // })
    this.$refs.multipleTable.bodyWrapper.addEventListener('scroll', (e) => {
      this.isScorllWidth = this.$refs.multipleTable.bodyWrapper.scrollLeft
      // console.log(e.targrt.scrollWidth)
    })
  },
  methods: {
    getDistance(e) {
      console.log(e)
    },
    // 处理所需展示的关键词
    setKeysData(_this) {
      _this.searchKeys = []
      for (let i = 0; i < _this.adSearchForm.length; i++) {
        // 如果是日期
        if (_this.adSearchForm[i].date) {
          if (_this.listParas[_this.adSearchForm[i].value].length > 0) {
            _this.searchKeys.push({ name: _this.adSearchForm[i].name, value: _this.listParas[_this.adSearchForm[i].value], prop: _this.adSearchForm[i].value })
          }
        }
        if (!_this.adSearchForm[i].date) {
          if (_this.listParas[_this.adSearchForm[i].value]) {
            _this.searchKeys.push({ name: _this.adSearchForm[i].name, value: _this.listParas[_this.adSearchForm[i].value], prop: _this.adSearchForm[i].value })
          }
        }
      }
    },
    // 处理订单
    setOrder(OrderID) {
      console.log(OrderID)
      this.$router.push({ name: 'orderHandle1', params: { orderid: OrderID, ishandler: '999' }})
      console.log(this.$route)
    },
    // 转采选择
    changePurchaseType() {
      this.listParas.PageIndex = 1
      this.getOrderList()
    },
    // 列表高级搜索
    AdsearchList() {
      this.listParas.PageIndex = 1
      if (this.listParas.DateArry.length > 0) {
        this.listParas.BeginDate = this.listParas.DateArry[0]
        this.listParas.EndDate = this.listParas.DateArry[1]
      }
      this.adisShow = false
      this.getOrderList()
    },
    // 清除某个筛选条件
    closeTag(tag) {
      if (Array.isArray(tag.value)) {
        this.listParas['BeginDate'] = null
        this.listParas['EndDate'] = null
      }
      if (this.listParas[tag.prop]) {
        this.listParas[tag.prop] = Array.isArray(tag.value) ? [] : ''
      }
      console.log(this.listParas)
      this.setKeysData(this)
      this.getOrderList()
    },
    // 获取页面按钮权限
    // async getBottons(elementId) {
    //   const _this = this
    //   await this.$jurisdiction.GetUserButtonListByMenuElementID({ elementId: elementId }).then(res => {
    //     if (res.RetCode === '0') {
    //       _this.userBottons = res.Message
    //     } else {
    //       _this.$message.error('加载页面出错： ' + res.Message)
    //       console.log('订单列表获取按钮权限报错：' + res.RetMsg)
    //     }
    //   })
    // },
    tablechage(val) {
      if (val['orderstatus']) {
        this.listParas.OrderStatus = val['orderstatus'].join(',')
      } else if (val['sendstatus']) {
        this.listParas.ShipmentStatus = val['sendstatus'].join(',')
      }
      this.listParas.PageIndex = 1
      this.getOrderList()
    },
    // 发货状态筛选
    chagneordersendstatusvalue(val) {
      this.listParas.ShipmentStatus = val
      this.listParas.PageIndex = 1
      this.getOrderList()
    },
    // 根据状态筛选
    chagneorderstatusvalue(val) {
      this.listParas.OrderStatus = val
      this.listParas.PageIndex = 1
      this.getOrderList()
    },
    // 搜索订单
    serchOrder() {
      this.listParas.PageIndex = 1
      this.getOrderList()
    },
    // 获取订单各个类型的数量
    getOrderTypeListNum() {
      const _this = this
      _this.fullscreenLoading = true
      $orderReq.GetOrderStatusStatistics().then((res) => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.dealOrderTypeListNum(res.Message, _this)
        } else {
          _this.$message('请求失败：' + res.RetMsg)
        }
      })
    },
    dealOrderTypeListNum(_data, _this) {
      // _this.ordderNumList
      _data.forEach((ele, index) => {
        switch (ele.OrderStatus) {
          case -1:
            // OrderStatus -1：全部， 21-等待人工处理，22-部分处理，23-已全部处理，103-全部关闭订单，1-我的订单
            _this.ordderNumList.num1 = ele.Count
            break
          case 21:
            // OrderStatus
            _this.ordderNumList.num2 = ele.Count
            break
          case 22:
            // OrderStatus
            _this.ordderNumList.num3 = ele.Count
            break
          case 23:
            // OrderStatus
            _this.ordderNumList.num4 = ele.Count
            break
          case 103:
            // OrderStatus
            _this.ordderNumList.num5 = ele.Count
            break
          case 1:
            // OrderStatus
            _this.ordderNumList.num6 = ele.Count
            break
          default: return null
        }
      })
    },
    // 添加到我的订单
    addFavorate(row) {
      const _this = this
      _this.fullscreenLoading = true
      // IsMyFavoriteOrder 1是，2否
      // OptionType  --操作类型：0加入 1取消
      $orderReq.Add_to_MyFavoriteOrder({ OrderId: row.OrderID, OptionType: row.IsMyFavoriteOrder === 1 ? 1 : 0 }).then((res) => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.$message('操作成功')
          _this.getOrderTypeListNum()
          if (row.IsMyFavoriteOrder === 1) {
            row.IsMyFavoriteOrder = 2
          } else {
            row.IsMyFavoriteOrder = 1
          }
        } else {
          _this.$message('请求失败：' + res.RetMsg)
        }
      })
    },
    // 改变数量类型时 type 1：状态传入 发货状态  2 传入订单状态
    changeType(val, type) {
      if (this.orderNumType !== val) {
        this.listParas.PageIndex = 1
      }
      this.orderNumType = val
      if (type === 1) {
        this.listParas.ShipmentStatus = this.orderNumType
        this.listParas.OrderStatus = null
      } else {
        this.listParas.ShipmentStatus = null
        this.listParas.OrderStatus = this.orderNumType
      }
      this.getOrderList()
    },
    // 鼠标划入行
    mouseEnter(row, column, cell, event) {
      // console.log('row', row)
      this.rowIdxMouse = row.IDX
      this.isOutMouse = false
      const a = this.orderList.findIndex(item => item.IDX === row.IDX)

      this.chioceSetIndex = a
      // scrollLeft
      this.$nextTick(() => {
        const bodyWidth = this.$refs.multipleTable.bodyWidth.split('px')[0]
        const dom = document.getElementsByClassName('hoverDom')[0]
        const table = document.getElementsByClassName('el-table')[0]
        const left_ = table.scrollWidth
        // console.log(table.pageXOffset)
        // console.log(table.scrollWidth)
        // console.log(parseInt(table.offsetWidth) - parseInt(table.scrollWidth))

        const clickBg = document.getElementById('clickBg')
        const a = bodyWidth - left_
        dom.style.height = '100%'
        dom.style.left = bodyWidth - 360 - a + this.isScorllWidth + 'px'
        clickBg.style.left = bodyWidth - 60 - a - 10 + this.isScorllWidth + 'px'
      })
    },
    // 表格离开
    mouseLive() {
      this.chioceSetIndex = null
    },
    // 键盘移除
    mouseOut(row, column, cell, event) {
      this.isOutMouse = true
      if (row.IDX === this.rowIdxMouse) return
      this.chioceSetIndex = null
      this.isShowDomAnimate = false
      return this.chioceSetIndex = null
    },
    // 切换动画
    hideSetDom() {
      this.$nextTick(() => {
        // const this_ = this
        this.isShowDomAnimate = !this.isShowDomAnimate
        const dom = document.getElementsByClassName('hoverDom')[0]
        const hei_ = document.getElementsByClassName('el-table__row')[0].offsetHeight
        // const clickBg = document.getElementById('clickBg')
        if (this.isShowDomAnimate) {
          setTimeout(() => {
            return dom.style.display = 'none'
          }, 1000)
          return
        }
        setTimeout(() => {
          return dom.style.display = 'block'
        }, 1000)
        dom.style.display = 'block'
        const btn_hei = document.getElementsByClassName('OperationButton')[0].offsetHeight
        return dom.style.top = Math.floor((hei_ - btn_hei) / 2) + 'px'

        // if (this.isShowDomAnimate) {
        //   setTimeout(() => {
        //     const dom = document.getElementsByClassName('hoverDom')[0]
        //     return dom.style.display = 'none'
        //   }, 1000)
        //   return
        // }
        // const dom = document.getElementsByClassName('hoverDom')[0]
        // return dom.style.display = 'block'
        // const dom = document.getElementsByClassName('hoverDom')[0]
        // return dom.style.display = 'block'
        // const dom = document.getElementsByClassName('hoverDom')[0]
        // // const hoverDom = document.getElementsByClassName('hoverDomRow')[0]
        // // const hoverWidth = document.getElementsByClassName('hoverWidth')[0]
        // // const clickBg = document.getElementById('clickBg')
        // // const qweqwer = this.$refs.multipleTable.bodyWidth.split('px')[0]
        // // dom.style.left = dom.style.left + qweqwer + dom.style.width
        // dom.style.display = 'none'
        // clickBg.style.width = '20px'
        // hoverWidth.style.display = 'none'
        // hoverDom.style.pointerEvents = 'none'
      })
    },
    // 表格行背景色
    tableRowClassName({ row, rowIndex, event }) {
      // if (rowIndex % 2 === 0) {
      //   return 'warning-row'
      // }
      // return 'success-row'
    },
    // 获取列表
    getOrderList() {
      const _this = this
      _this.fullscreenLoading = true
      const paras = {}
      Object.assign(paras, _this.listParas)
      _this.setKeysData(_this) // 展示搜索条件
      _this.tableDataloading = true
      delete paras.DateArry
      $orderReq.GetOrderList(paras).then((res) => {
        _this.tableDataloading = false
        if (res.RetCode === '0') {
          _this.orderList = res.Message
          _this.tableTotalSize = res.Total
          _this.fullscreenLoading = false
        } else {
          _this.tableDataloading = false
          _this.fullscreenLoading = false
          _this.$message('获取列表失败：' + res.RetMsg)
        }
      }).catch(err => {
        _this.$message('接口请求失败：' + err)
        _this.tableDataloading = false
      })
    },
    // 验证批量关闭订单
    comfirmColseOrder() {
      const _this = this
      if (!_this.checkedOrderList || _this.checkedOrderList.length < 1) {
        _this.$message.warning('请选择需要关闭的订单')
        return false
      }
      _this.$confirm('确认关闭选中的订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.ColseOrder(-1)
      }).catch(() => {
      })
    },
    //  1 _oderId 大于0 则单个关闭
    ColseOrder(_oderId) {
      const _this = this
      _this.fullscreenLoading = true
      const paras = {
        OrderIDS: '',
        Stauts: 100
      }
      if (_oderId && _oderId > 0) {
        paras.OrderIDS = _oderId
      } else {
        paras.OrderIDS = _this.checkedOrderList.join(',')
      }
      $orderReq.OrderInfoStatusUpdate(paras).then((res) => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getOrderList()
          return _this.$message.success('操作成功')
        }
        return _this.$message.error('操作失败:' + res.RetMsg)
      })
    },
    // 处理导出订单
    dealExportData() {
      // const _this = this
      // SelectIDList this.listParas.SelectIDList
      this.$refs.ExportLoading.open()
      this.listParas.SelectIDList = this.checkedOrderList
      this.$api.ExportOrderAndDetailList(this.listParas).then(res => {
        delete this.listParas.SelectIDList
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        const name = this.$minCommon.getExportName('订单处理')
        this.$refs.ExportLoading.success(res.Message, name)
        this.$minCommon.downloadFile(res.Message, name)
      })
      // if (!_this.checkedOrderList || _this.checkedOrderList.length < 1) {
      //   _this.$confirm('确认导出全部数据？', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     _this.exportData()
      //   }).catch(() => {
      //   })
      // } else {
      //   // 导出选中数据
      //   _this.exportDataByidx()
      // }
    },
    // 导出订单byidx
    exportDataByidx() {
      const _this = this
      _this.fullscreenLoading = true
      $orderReq.ExportOrderList({ OrderIds: _this.checkedOrderList.join(',') }).then((res) => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.dealExportReturnData(res.Message)
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败')
        }
      })
    },
    // 导出订单根据条件
    exportData() {
      const _this = this
      _this.fullscreenLoading = true
      $orderReq.ExportOrderList(_this.listParas).then((res) => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.dealExportReturnData(res.Message)
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败')
        }
      })
    },
    // 处理导出接口返回文件
    dealExportReturnData(_data) {
      $commonJs.downloadFile(_data, $commonJs.guid() + '.xlsx')
    },
    // 处理选中数据
    orderlisthandleSelectionChange(val) {
      this.checkedOrderList = []
      val.forEach((ele) => {
        this.checkedOrderList.push(ele.OrderID)
      })
    },
    // 翻页
    orderlisthandleCurrentChange(val) {
      this.listParas.PageIndex = val
      this.getOrderList()
    },
    // 订单发货状态
    ordersendstatusfilterTag(type) {
      // this.statusType = type
      // return row.ShipmentStatus === value
    },
    // 订单状态 筛选
    orderstatusfilterTag(type) {
      // this.statusType = type
      // if (value === 100) {
      //   if ([100, 101, 102, 103].indexOf(row.OrderStatus) > -1) {
      //     return true
      //   }
      // } else {
      //   return row.OrderStatus === value
      // }
    },
    getsendInfo(index) {
      switch (index) {
        case 0:
          return { text: '未发货', value: 0 }
        case 4:
          return { text: '已部分发货', value: 4 }
        case 3:
          return { text: '已发货', value: 3 }
        default:
          return { text: '暂无', type: 'warning' }
      }
    },
    // 订单状态
    getInfo(index) {
      /*
        --订单状态统计：0-前台未审核；1-前台已审核；11-系统尚未确认；2-后台已确认；21-需人工介入；3-已全部发货；4-已部分发货；
       5-用户已确认；100-用户手动关闭；101-后台手动关闭；102-后台自动关闭,103-所有关闭订单
      */
      switch (index) {
        case 21:
          return { text: '待处理订单', value: 21 }
        case 22:
          return { text: '部分处理订单', value: 22 }
        case 23:
          return { text: '已处理订单', value: 23 }
        case 100:
          return { text: '已关闭订单', value: 100 }
        case 101:
          return { text: '已关闭订单', value: 101 }
        case 102:
          return { text: '已关闭订单', value: 102 }
        case 103:
          return { text: '已关闭订单', value: 103 }
        default:
          return { text: '暂无', type: 'warning' }
      }
    },
    // 订单来源
    getOrderIDSourceInfo(index) {
      // 10-手工创建采购申请，20-预订单创建采购申请，30-采购控管创建采购申请)
      switch (index) {
        case 10:
          return { text: '手工创建采购申请', value: 0 }
        case 20:
          return { text: '预订单创建采购申请', value: 1 }
        case 30:
          return { text: '采购控管创建采购申请', value: 11 }
        case 14:
          return { text: 'SAAS下单', value: 14 }
        default:
          return { text: '暂无', type: 'warning' }
      }
    },
    ends() {}
  } // methods ends
}
export default orderList
